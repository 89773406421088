import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MoreFromCollection from "../components/layouts/MoreFromCollection";
import img from "../assets/images/avatar/avt-3.jpg";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  getMerketplaceContract,
  getPublicKey,
  getWallet,
  NFTContract,
} from "./../utils/GetContract";
import { Provider, bn } from "fuels";
import Modal from "react-bootstrap/Modal";
import { LoadingDots } from "../components/LoadingDots";
import { AttrCard } from "../components/AttrCard";
import { NFTAbi__factory } from "../contracts/nft";

const formatAddress = (address) =>
  typeof address === "string"
    ? `${address.slice(0, 5)}...${address.slice(-3)}`
    : "";

const ItemDetails01 = () => {
  const [data, setData] = useState();
  const [price, setPrice] = useState();
  const [newPrice, setNewPrice] = useState();
  const [nftHistory, setNftHistory] = useState();
  const [moreFromCollection, setMoreFromCollection] = useState();
  const [listed, setListed] = useState(false);
  const [owner, setOwner] = useState(false);
  const [offer, setOffer] = useState();
  const [offerer, setOfferer] = useState();
  const [offerAmount, setOfferAmount] = useState();
  const [sameOfferer, setSameOfferer] = useState(false);
  const [contractCall, setContractCall] = useState(false);
  const [wallet, setWallet] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [error, setError] = useState("");

  const { contract_id, token } = useParams();
  const navigate = useNavigate();

  const activityTableHeader = [
    // "",
    "name",
    "transaction type",
    "seller",
    "buyer",
    "total amount",
    "time",
  ];

  const loadData = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_WALLET_NFTS_URL}/nft/${contract_id.replace(
        "0x",
        ""
      )}/${token}`
    );
    let result = await data.json();
    console.log(result);
    setData({
      name: result.nft_data.name,
      description: result.nft_data.description,
      imgUrl: result.nft_data.image,
      attributes: result.nft_data.attributes,
    });
    const publicKey = await getPublicKey();
    if (publicKey) {
      setWallet(publicKey);
    }
    if (publicKey === "0x" + result.owner_address) {
      setOwner(true);
    }
  };

  const formatTimestamp = (timestamp) => {
    const hoursAgo = Math.floor(
      (Date.now() - timestamp * 1000) / (1000 * 60 * 60)
    );
    if (hoursAgo >= 24) {
      const daysAgo = Math.floor(hoursAgo / 24);
      return `${daysAgo}d`;
    } else {
      return `${hoursAgo}h`;
    }
  };

  const loadNftData = async () => {
    setOfferer(null);
    const data = await fetch(
      `${
        process.env.REACT_APP_AMRKETPLACE_API_URL
      }/listed-nft/${contract_id.replace("0x", "")}/${token}`
    );
    let result = await data.json();
    setListed(result.status);
    setPrice(result.price);
    if (result.offer) {
      setOfferer("0x" + result.offer.user_address);
      setOffer(result.offer.price / 1e9);
    }
    const publicKey = await getPublicKey();
    if (result.offer && publicKey === "0x" + result.offer.user_address) {
      setSameOfferer(true);
    }
  };

  const loadHistory = async () => {
    // let provider = new Provider("https://node-beta-2.fuel.network/graphql");
    let wallet = await getWallet();
    const provider = wallet.provider;
    const block = Number(await provider.getBlockNumber());
    const { current_time } = Number(await provider.getBlock(block)?.time);
    // console.log(await provider.getBlock(block));
    let array = [];
    const data = await fetch(
      `${
        process.env.REACT_APP_AMRKETPLACE_API_URL
      }/nft-history/${contract_id.replace("0x", "")}/${token}`
    );
    let result = await data.json();
    console.log(result);
    setNftHistory(result);
    // console.log(result);
    // for (const h of result) {
    //   const time = Number(await provider.getBlock(h.block_height)?.time);
    //   array.push({
    //     transaction_type: h.transaction_type,
    //     seller: h.address,
    //     buyer: h.buyer_address,
    //     amount: h.price / 1e9,
    //     time: current_time - time,
    //   });
    // }
    // setNftHistory(array);
  };

  const loadMoreFromCollection = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_AMRKETPLACE_API_URL}/collection-nfts`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: contract_id.replace("0x", ""),
          status: true,
          limit: 6,
        }),
      }
    );
    const result = await data.json();
    setMoreFromCollection(result.data.filter((nft) => nft.token_id !== +token));
  };

  useEffect(() => {
    loadData();
    loadNftData();
    loadHistory();
    loadMoreFromCollection();
  }, [token]);

  const make_offer = async () => {
    setContractCall(true);
    try {
      const amount = offerAmount * 1e9;
      const contract = await getMerketplaceContract();
      const make_offer = await contract.functions
        .make_offer({ value: contract_id }, parseInt(token))
        .txParams({ gasPrice: 1 })
        .callParams({
          forward: [amount],
        })
        .call();
      console.log("make_offer: ", make_offer);
    } catch {
      console.log("some error");
    } finally {
      setShowOfferModal(false);
      setContractCall(false);
    }
  };

  const cancel_listing = async () => {
    setContractCall(true);
    try {
      const contract = await getMerketplaceContract();
      const delist_nft = await contract.functions
        .delist_nft({ value: contract_id }, parseInt(token))
        .addContracts([NFTContract(contract_id)])
        .txParams({ gasPrice: 1 })
        // .simulate();
        .call();
      console.log("delist_nft: ", delist_nft);
      navigate("/author/" + wallet);
    } finally {
      setContractCall(false);
    }
    // alert("Congratulations on your fuel nft!");
  };

  const buy_nft = async () => {
    setContractCall(true);
    try {
      const contract = await getMerketplaceContract();
      const buy_nft = await contract.functions
        .buy_nft({ value: contract_id }, parseInt(token))
        .addContracts([NFTContract(contract_id)])
        .txParams({ gasPrice: 1 })
        .callParams({
          forward: [price],
        })
        // .simulate();
        .call();
      console.log("buy_nft: ", buy_nft);
      alert("Congratulations on your fuel nft!");
      // navigate("/author/" + wallet);
      window.location.reload();
    } catch (error) {
      console.log("error: ", error);
      alert("Buying failed, try again!");
    } finally {
      setContractCall(false);
    }
  };

  const accept_offer = async () => {
    setContractCall(true);
    try {
      if (listed) {
        const contract = await getMerketplaceContract();
        const accept_offer = await contract.functions
          .accept_offer({ value: contract_id }, parseInt(token))
          .addContracts([NFTContract(contract_id)])
          .txParams({ gasPrice: 1 })
          .call();
        console.log("change_nft_price: ", accept_offer);
        alert("Congratulations on your fuel nft selling!");
        navigate("/author/" + wallet);
      } else {
        const wallet = await getWallet();
        const contractInstance = await getMerketplaceContract();
        const nftcontractInstance = NFTAbi__factory.connect(
          contract_id,
          wallet,
          {
            cache: false,
          }
        );
        const calls = [
          nftcontractInstance.functions.approve(
            { ContractId: { value: contractInstance.id } },
            token
          ),
          contractInstance.functions.accept_offer(
            { value: contract_id },
            parseInt(token)
          ),
        ];
        const scope = contractInstance
          .multiCall(calls)
          .addContracts([NFTContract(contract_id)]);

        const results = await scope.txParams({ gasPrice: 10 }).call();
        console.log("calls: ", results);
        navigate("/author/" + wallet);
      }
    } catch {
      console.log("some error");
    } finally {
      setContractCall(false);
    }
  };

  const change_listing_price = async () => {
    setContractCall(true);
    try {
      const contract = await getMerketplaceContract();
      const change_nft_price = await contract.functions
        .change_nft_price(
          { value: contract_id },
          parseInt(token),
          bn(newPrice * 1e9)
        )
        .addContracts([NFTContract(contract_id)])
        .txParams({ gasPrice: 1 })
        // .simulate();
        .call();
      console.log("change_nft_price: ", change_nft_price);
      setPrice(newPrice);
      setShowModal(false);
    } catch {
      console.log("some error");
    } finally {
      setContractCall(false);
    }
  };

  const handlePrice = (e) => {
    setNewPrice(e.target.value);
  };

  const handleOffer = (e) => {
    const price = e.target.value;
    if (price < offer) {
      setError("Amount should be higher then current offer");
    } else {
      setOfferAmount(price);
      setError("");
    }
  };

  const MakeOfferModal = (
    <Modal
      onHide={() => setShowOfferModal(false)}
      show={showOfferModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3 className="text-center">Make Offer</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="changeListing">
          <div>
            <h4>Enter an Amount for offer</h4>
            <div className="input">
              <input
                min="0"
                type="number"
                onChange={handleOffer}
                name="price"
                className="style-2"
              />
              <span>ETH</span>
            </div>
            {error ? <div className="error">{error}</div> : <></>}
          </div>
          <div className="d-flex justify-content-between mt-4 actions">
            <button
              className="sc-button fl-button style-1"
              onClick={make_offer}
            >
              <span>Continue</span>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );

  const ChangeListing = (
    <Modal
      onHide={() => setShowModal(false)}
      show={showModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3 className="text-center">Edit listing</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="changeListing">
          {data ? (
            <div className="details row mb-5">
              <div className="nftImage col-3">
                <img src={data.imgUrl} alt={"alt"} />
              </div>
              <div className="col-6">
                <h4>{data.name}</h4>
                {/* <p className="textMuted">Expire in 27 days</p> */}
              </div>
              <div className="col-3">
                <h5>{price / 1e9} ETH</h5>
                {/* <p className="textMuted">$2,464.98</p> */}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div>
            <h4>Set new price</h4>
            <p className="textMuted">
              If you want to increase or decrease the price, you will be
              prompted to cancel all of your existing first. This will cost
              gas.&nbsp;
              <a href="#">Learn more</a>.
            </p>
            <div className="input">
              <input
                type="number"
                onChange={handlePrice}
                name="price"
                className="style-2"
              />
              <span>ETH</span>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-4 actions">
            <button
              onClick={() => cancel_listing}
              className="sc-button loadmore fl-button pri-3 mb-0"
            >
              <span>Cancel listing</span>
            </button>
            <button
              disabled={!newPrice}
              className="sc-button fl-button style-1"
              onClick={change_listing_price}
            >
              <span>Continue</span>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );

  if (!data) return <LoadingDots headerFix={true} />;
  return (
    <div className="item-details">
      {contractCall ? <LoadingDots fullScreen={true} /> : <></>}

      <div className="tf-section tf-item-details">
        <div className="themesflat-container mt-5">
          <div className="row">
            <div className="col-xl-4 col-md-12">
              <div className="content-left">
                <div className="media">
                  <img src={data.imgUrl} alt="Fuelart" />
                </div>
              </div>
            </div>
            {ChangeListing}
            {MakeOfferModal}
            <div className="col-xl-6 col-md-12">
              {data ? (
                <div className="content-right">
                  <div className="sc-item-details">
                    <h2 className="style2">{data.name} </h2>
                    {/* <div className="meta-item">
                      <div className="left">
                        <span className="viewed eye">225</span>
                        <span
                          to="/login"
                          className="liked heart wishlist-button mg-l-8"
                        >
                          <span className="number-like">100</span>
                        </span>
                      </div>
                      <div className="right">
                        <Link to="#" className="share"></Link>
                        <Link to="#" className="option"></Link>
                      </div>
                    </div> */}
                    {/* <div className="client-infor sc-card-product">
                      <div className="meta-info">
                        <div className="author">
                          <div className="avatar">
                            <img src={img} alt="Fuelart" />
                          </div>
                          <div className="info">
                            <span>Owned By</span>
                            <h6>
                              {" "}
                              <Link to="/author-02">Ralph Garraway</Link>{" "}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="meta-info">
                        <div className="author">
                          <div className="avatar">
                            <img src={img} alt="Fuelart" />
                          </div>
                          <div className="info">
                            <span>Create By</span>
                            <h6>
                              {" "}
                              <Link to="/author-02">
                                Freddie Carpenter
                              </Link>{" "}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <p>{data.description}</p>

                    <div className="meta-item-details style2">
                      {listed ? (
                        <div className="box">
                          <div className="item meta-price">
                            <span className="heading">Current Price</span>
                            <div className="price">
                              <div className="price-box">
                                <h5>{price / 1e9} ETH</h5>
                                {/* <span>= $12.246</span> */}
                              </div>
                            </div>
                          </div>
                          {owner ? (
                            <button
                              className="sc-button loadmore style bag fl-button pri-3 w-100"
                              onClick={() => setShowModal(true)}
                            >
                              <span>Change Listing</span>
                            </button>
                          ) : (
                            <button
                              className="sc-button loadmore style bag fl-button pri-3 w-100"
                              onClick={buy_nft}
                            >
                              <span>Buy</span>
                            </button>
                          )}
                        </div>
                      ) : (
                        <>
                          {owner ? (
                            <div className="box">
                              <Link
                                className="sc-button loadmore style bag fl-button pri-3 w-100"
                                to={`/sell/${contract_id}/${token}`}
                              >
                                <span>Sell</span>
                              </Link>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      <div className="box">
                        <div className="item meta-price">
                          <span className="heading">Current Offer</span>
                          <div className="price">
                            <div className="price-box">
                              <h5> {offer ? offer : "--"} ETH</h5>
                              {/* <span>= $12.246</span> */}
                            </div>
                          </div>
                        </div>
                        {owner && offer ? (
                          <button
                            className="sc-button loadmore style bag fl-button pri-3 w-100"
                            onClick={accept_offer}
                          >
                            <span>Accept Offer</span>
                          </button>
                        ) : (
                          <></>
                        )}
                        {sameOfferer && offer ? (
                          <button
                            className="sc-button loadmore style bag fl-button pri-3 w-100 "
                            onClick={() => setShowOfferModal(true)}
                          >
                            <span>Change Offer</span>
                          </button>
                        ) : (
                          <></>
                        )}
                        {!owner && !sameOfferer ? (
                          <button
                            className="sc-button loadmore style bag fl-button pri-3 w-100 "
                            onClick={() => setShowOfferModal(true)}
                          >
                            <span>Make Offer</span>
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    {!wallet ? (
                      <div className="box">
                        <button className="sc-button loadmore style bag fl-button pri-3 w-100">
                          <span>Connect Wallet</span>
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}

                    {/* <div className="meta-item-details">
                      <div className="bt1">
                        <button
                          className="sc-button loadmore style bag fl-button pri-3 w-100"
                          onClick={() => setShowModal(true)}
                        >
                          <span>Change Listing</span>
                        </button>
                      </div>

                      <div className="bt2">
                        <button
                          className="sc-button loadmore style bag fl-button pri-3 w-100"
                          onClick={accept_offer}
                        >
                          <span>Accept Offer</span>
                        </button>
                      </div>
                    </div>
                    <div className="meta-item-details">
                      <div className="bt1">
                        <button
                          className="sc-button loadmore style bag fl-button pri-3 w-100 "
                          onClick={buy_nft}
                        >
                          <span>Buy Now</span>
                        </button>
                      </div>

                      <div className="bt2">
                        <button
                          className="sc-button loadmore style bag fl-button pri-3 w-100 "
                          onClick={() => setShowOfferModal(true)}
                        >
                          <span>Change Offer</span>
                        </button>
                        <button
                          className="sc-button loadmore style bag fl-button pri-3 w-100 "
                          onClick={() => setShowOfferModal(true)}
                        >
                          <span>Make Offer</span>
                        </button>
                      </div>
                    </div> */}
                    <div className="bt1">
                      {/* <Link
                        to={`/sell/${contract_id}/${token}`}
                        className="sc-button loadmore style bag fl-button pri-3 w-100"
                      >
                        <span>Sell</span>
                      </Link> */}
                    </div>
                    <div className="flat-tabs themesflat-tabs">
                      <Tabs>
                        <TabList>
                          <Tab>Activities</Tab>
                          {/* <Tab>Info</Tab> */}
                          <Tab>Description</Tab>
                          <Tab>Attributes</Tab>
                        </TabList>

                        <TabPanel>
                          <div className="activities">
                            <table>
                              <thead>
                                <tr className="text-uppercase">
                                  {activityTableHeader.map((header, index) => (
                                    <th key={index}>{header}</th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {nftHistory &&
                                  nftHistory.map((nfth, index) => (
                                    <tr key={index}>
                                      <td>{data.name}</td>
                                      <td>{nfth.transaction_type}</td>
                                      <td>
                                        <Link
                                          title={"0x" + nfth.address}
                                          to={`/author/${"0x" + nfth.address}`}
                                        >
                                          {formatAddress("0x" + nfth.address)}
                                        </Link>
                                      </td>
                                      <td>
                                        <Link
                                          to={`/author/${
                                            "0x" + nfth.buyer_address
                                          }`}
                                        >
                                          {formatAddress(
                                            "0x" + nfth.buyer_address
                                          )}
                                        </Link>
                                      </td>
                                      <td>
                                        {(nfth.price / 1e9).toFixed(3)} ETH
                                      </td>
                                      <td>
                                        {formatTimestamp(
                                          nfth.block_produced_time
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="provenance">
                            <p>{data.description}</p>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="attributes">
                            {data.attributes.length ? (
                              data.attributes.map((attr) => (
                                <AttrCard
                                  // key={data.name}
                                  attrName={attr.trait_type}
                                  attrValue={attr.value}
                                />
                              ))
                            ) : (
                              <></>
                            )}
                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {moreFromCollection && moreFromCollection.length ? (
        <MoreFromCollection data={moreFromCollection} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ItemDetails01;

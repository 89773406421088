import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import img1 from "../assets/images/test/anger.webp";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import data from "../assets/fake-data/data-popular-collection";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export const Launchpad = () => {
  const contractId =
    "0x5d4f41b5e6249cca9d0a7c0af4a84ad802de75076a42e8af538eef76f0900729";
  return (
    <div>
      {/* <Header /> */}
      <section className="tf-contact tf-section">
        <div className="themesflat-container mt-5">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="box-feature-contact">
                <img src={img1} alt="Fuelart" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <h2 className="tf-title-heading style-2 mg-bt-12">
                Fuel Nft Collection: First one <br />
              </h2>
              <h5 className="sub-title style-1">
                This is a demo project. <br />
                <a href="#">Read More!</a>
              </h5>
              <Link
                to={`/launchpad/${contractId}`}
                className="sc-button fl-button pri-3"
              >
                <span>Go to Launchpad</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <section>
        <div className="create-item">
          <div className="tf-create-item tf-section">
            <div className="themesflat-container">
              <div className="form-create-item">
                <div className="flat-tabs tab-create-item">
                  <Tabs>
                    <TabList className="tabList">
                      <Tab>Live</Tab>
                      <Tab>Claims</Tab>
                    </TabList>
                    <TabPanel>
                      <Row>
                        {data.map((item, index) => (
                          <Col
                            className="col-lg-4 col-md-6 col-12 col12"
                            key={index}
                          >
                            <CollectionCard item={item} />
                          </Col>
                        ))}
                      </Row>
                      <h2 className="tf-title pad-l-7">Upcoming</h2>
                      <Row>
                        {data.map((item, index) => (
                          <Col
                            className="col-lg-4 col-md-6 col-12 col-12"
                            key={index}
                          >
                            <CollectionCard item={item} />
                          </Col>
                        ))}
                      </Row>
                      <h2 className="tf-title pad-l-7">Ended</h2>
                      <Row>
                        {data.map((item, index) => (
                          <Col
                            className="col-lg-4 col-md-6 col-12 col-12"
                            key={index}
                          >
                            <CollectionCard item={item} />
                          </Col>
                        ))}
                      </Row>
                    </TabPanel>
                    <TabPanel>
                      <Row>
                        {data.map((item, index) => (
                          <Col
                            className="col-lg-4 col-md-6 col-12 col12"
                            key={index}
                          >
                            <CollectionCard item={item} />
                          </Col>
                        ))}
                      </Row>
                      <h2 className="tf-title pad-l-7">Upcoming</h2>
                      <Row>
                        {data.map((item, index) => (
                          <Col
                            className="col-lg-4 col-md-6 col-12 col-12"
                            key={index}
                          >
                            <CollectionCard item={item} />
                          </Col>
                        ))}
                      </Row>
                      <h2 className="tf-title pad-l-7">Ended</h2>
                      <Row>
                        {data.map((item, index) => (
                          <Col
                            className="col-lg-4 col-md-6 col-12 col-12"
                            key={index}
                          >
                            <CollectionCard item={item} />
                          </Col>
                        ))}
                      </Row>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <Footer /> */}
    </div>
  );
};

const CollectionCard = (props) => (
  <div className="swiper-container show-shadow carousel4 button-arow-style">
    <div className="swiper-wrapper">
      <div className="swiper-slide">
        <div className="slider-item">
          <div className="sc-card-collection style-2 home2">
            <div className="card-bottom">
              <div className="author">
                <div className="sc-author-box style-2">
                  <div className="author-avatar">
                    <img src={props.item.imgAuthor} alt="" className="avatar" />
                    <div className="badge">
                      <i className="ripple"></i>
                    </div>
                  </div>
                </div>
                <div className="content">
                  <h4>
                    <Link to="/authors-01">{props.item.title}</Link>
                  </h4>
                  <div className="infor">
                    <span>Created by</span>
                    <span className="name">
                      <Link to="/authors-02">{props.item.name}</Link>
                    </span>
                  </div>
                </div>
              </div>
              <Link to="/login" className="wishlist-button public heart">
                <span className="number-like"> 100</span>
              </Link>
            </div>
            <Link to="/authors-02">
              <div className="media-images-collection">
                <div className="box-left">
                  <img src={props.item.imgleft} alt="Fuelart" />
                </div>
                <div className="box-right">
                  <div className="top-img">
                    <img src={props.item.imgright1} alt="Fuelart" />
                    <img src={props.item.imgright2} alt="Fuelart" />
                  </div>
                  <div className="bottom-img">
                    <img src={props.item.imgright3} alt="Fuelart" />
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

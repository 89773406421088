/* Autogenerated file. Do not edit manually. */

/* tslint:disable */
/* eslint-disable */

/*
  Fuels version: 0.35.0
  Forc version: 0.35.3
  Fuel-Core version: 0.17.3
*/

import { Interface, Contract } from "fuels";
import type { Provider, Account, AbstractAddress } from "fuels";
import type { NftMarketplaceAbi, NftMarketplaceAbiInterface } from "../NftMarketplaceAbi";

const _abi = {
  "types": [
    {
      "typeId": 0,
      "type": "()",
      "components": [],
      "typeParameters": null
    },
    {
      "typeId": 1,
      "type": "b256",
      "components": null,
      "typeParameters": null
    },
    {
      "typeId": 2,
      "type": "bool",
      "components": null,
      "typeParameters": null
    },
    {
      "typeId": 3,
      "type": "enum AccessError",
      "components": [
        {
          "name": "NFTAlreadyListed",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "NFTNotListed",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "ProtocolFeeDoesNotExist",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "SenderCannotSetAccessControl",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "SenderIsOwner",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "SenderNotAdmin",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "SenderNotArtist",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "SenderNotOwner",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "SenderNotOwnerOrApproved",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "SenderDidNotMakeOffer",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "AuctionIsNotOpen",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "AuctionDoesNotExist",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "SenderIsNotSeller",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "AuctionIsNotClosed",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "NoRoyaltyFound",
          "type": 0,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 4,
      "type": "enum Identity",
      "components": [
        {
          "name": "Address",
          "type": 9,
          "typeArguments": null
        },
        {
          "name": "ContractId",
          "type": 11,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 5,
      "type": "enum InitError",
      "components": [
        {
          "name": "AuctionDurationNotProvided",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "AdminIsNone",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "CannotReinitialize",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "ReserveLessThanInitialPrice",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "CannotAcceptMoreThanOneNFT",
          "type": 0,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 6,
      "type": "enum InputError",
      "components": [
        {
          "name": "AdminDoesNotExist",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "PriceCantBeZero",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "OffererNotExists",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "IncorrectAmountProvided",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "OfferNotExists",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "AmountCantBeLessThanLastOfferer",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "InitialPriceNotMet",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "AuctionDoesNotExist",
          "type": 0,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 7,
      "type": "enum Option",
      "components": [
        {
          "name": "None",
          "type": 0,
          "typeArguments": null
        },
        {
          "name": "Some",
          "type": 8,
          "typeArguments": null
        }
      ],
      "typeParameters": [
        8
      ]
    },
    {
      "typeId": 8,
      "type": "generic T",
      "components": null,
      "typeParameters": null
    },
    {
      "typeId": 9,
      "type": "struct Address",
      "components": [
        {
          "name": "value",
          "type": 1,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 10,
      "type": "struct AdminChangedEvent",
      "components": [
        {
          "name": "mew_admin",
          "type": 4,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 11,
      "type": "struct ContractId",
      "components": [
        {
          "name": "value",
          "type": 1,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 12,
      "type": "struct ListNft",
      "components": [
        {
          "name": "owner",
          "type": 4,
          "typeArguments": null
        },
        {
          "name": "price",
          "type": 22,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 13,
      "type": "struct NFTBoughtEvent",
      "components": [
        {
          "name": "buyer",
          "type": 4,
          "typeArguments": null
        },
        {
          "name": "seller",
          "type": 4,
          "typeArguments": null
        },
        {
          "name": "nft_contract",
          "type": 11,
          "typeArguments": null
        },
        {
          "name": "token_id",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "price",
          "type": 22,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 14,
      "type": "struct NFTChangeOfferEvent",
      "components": [
        {
          "name": "offerer",
          "type": 4,
          "typeArguments": null
        },
        {
          "name": "nft_contract",
          "type": 11,
          "typeArguments": null
        },
        {
          "name": "token_id",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "new_price",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "old_price",
          "type": 22,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 15,
      "type": "struct NFTDeListedEvent",
      "components": [
        {
          "name": "owner",
          "type": 4,
          "typeArguments": null
        },
        {
          "name": "nft_contract",
          "type": 11,
          "typeArguments": null
        },
        {
          "name": "token_id",
          "type": 22,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 16,
      "type": "struct NFTListedEvent",
      "components": [
        {
          "name": "owner",
          "type": 4,
          "typeArguments": null
        },
        {
          "name": "nft_contract",
          "type": 11,
          "typeArguments": null
        },
        {
          "name": "token_id",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "price",
          "type": 22,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 17,
      "type": "struct NFTOfferAcceptEvent",
      "components": [
        {
          "name": "offerer",
          "type": 4,
          "typeArguments": null
        },
        {
          "name": "owner",
          "type": 4,
          "typeArguments": null
        },
        {
          "name": "nft_contract",
          "type": 11,
          "typeArguments": null
        },
        {
          "name": "token_id",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "price",
          "type": 22,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 18,
      "type": "struct NFTOfferEvent",
      "components": [
        {
          "name": "offerer",
          "type": 4,
          "typeArguments": null
        },
        {
          "name": "nft_contract",
          "type": 11,
          "typeArguments": null
        },
        {
          "name": "token_id",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "price",
          "type": 22,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 19,
      "type": "struct NFTPriceChangeEvent",
      "components": [
        {
          "name": "owner",
          "type": 4,
          "typeArguments": null
        },
        {
          "name": "nft_contract",
          "type": 11,
          "typeArguments": null
        },
        {
          "name": "token_id",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "old_price",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "new_price",
          "type": 22,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 20,
      "type": "struct OfferNft",
      "components": [
        {
          "name": "offerer",
          "type": 4,
          "typeArguments": null
        },
        {
          "name": "price",
          "type": 22,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 21,
      "type": "struct Royalty",
      "components": [
        {
          "name": "artist",
          "type": 4,
          "typeArguments": null
        },
        {
          "name": "royalty",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "total_royalty",
          "type": 22,
          "typeArguments": null
        }
      ],
      "typeParameters": null
    },
    {
      "typeId": 22,
      "type": "u64",
      "components": null,
      "typeParameters": null
    }
  ],
  "functions": [
    {
      "inputs": [
        {
          "name": "id",
          "type": 11,
          "typeArguments": null
        },
        {
          "name": "token_id",
          "type": 22,
          "typeArguments": null
        }
      ],
      "name": "accept_offer",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "admin",
      "output": {
        "name": "",
        "type": 4,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "type": 11,
          "typeArguments": null
        },
        {
          "name": "token_id",
          "type": 22,
          "typeArguments": null
        }
      ],
      "name": "buy_nft",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        },
        {
          "name": "payable",
          "arguments": []
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "type": 11,
          "typeArguments": null
        },
        {
          "name": "token_id",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "price",
          "type": 22,
          "typeArguments": null
        }
      ],
      "name": "change_nft_price",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "type": 11,
          "typeArguments": null
        },
        {
          "name": "token_id",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "price",
          "type": 22,
          "typeArguments": null
        }
      ],
      "name": "change_offer",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "payable",
          "arguments": []
        },
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "access_control",
          "type": 2,
          "typeArguments": null
        },
        {
          "name": "admin",
          "type": 4,
          "typeArguments": null
        }
      ],
      "name": "constructor",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "type": 11,
          "typeArguments": null
        },
        {
          "name": "token_id",
          "type": 22,
          "typeArguments": null
        }
      ],
      "name": "delist_nft",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [],
      "name": "get_balance",
      "output": {
        "name": "",
        "type": 22,
        "typeArguments": null
      },
      "attributes": null
    },
    {
      "inputs": [],
      "name": "get_default_protocol_fee",
      "output": {
        "name": "",
        "type": 22,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "type": 11,
          "typeArguments": null
        },
        {
          "name": "token_id",
          "type": 22,
          "typeArguments": null
        }
      ],
      "name": "get_nft_data",
      "output": {
        "name": "",
        "type": 7,
        "typeArguments": [
          {
            "name": "",
            "type": 12,
            "typeArguments": null
          }
        ]
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "type": 11,
          "typeArguments": null
        },
        {
          "name": "token_id",
          "type": 22,
          "typeArguments": null
        }
      ],
      "name": "get_offer",
      "output": {
        "name": "",
        "type": 7,
        "typeArguments": [
          {
            "name": "",
            "type": 20,
            "typeArguments": null
          }
        ]
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "type": 11,
          "typeArguments": null
        }
      ],
      "name": "get_protocol_fee",
      "output": {
        "name": "",
        "type": 22,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "type": 11,
          "typeArguments": null
        }
      ],
      "name": "get_royalty",
      "output": {
        "name": "",
        "type": 7,
        "typeArguments": [
          {
            "name": "",
            "type": 21,
            "typeArguments": null
          }
        ]
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "type": 11,
          "typeArguments": null
        },
        {
          "name": "token_id",
          "type": 22,
          "typeArguments": null
        },
        {
          "name": "price",
          "type": 22,
          "typeArguments": null
        }
      ],
      "name": "list_nft",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "type": 11,
          "typeArguments": null
        },
        {
          "name": "token_id",
          "type": 22,
          "typeArguments": null
        }
      ],
      "name": "make_offer",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        },
        {
          "name": "payable",
          "arguments": []
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "admin",
          "type": 4,
          "typeArguments": null
        }
      ],
      "name": "set_admin",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "type": 11,
          "typeArguments": null
        },
        {
          "name": "amount",
          "type": 22,
          "typeArguments": null
        }
      ],
      "name": "set_protocol_fee",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "type": 11,
          "typeArguments": null
        },
        {
          "name": "royalty",
          "type": 21,
          "typeArguments": null
        }
      ],
      "name": "set_royalty",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "amount",
          "type": 22,
          "typeArguments": null
        }
      ],
      "name": "withdraw_balance",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read"
          ]
        }
      ]
    },
    {
      "inputs": [
        {
          "name": "id",
          "type": 11,
          "typeArguments": null
        },
        {
          "name": "amount",
          "type": 22,
          "typeArguments": null
        }
      ],
      "name": "withdraw_royalty",
      "output": {
        "name": "",
        "type": 0,
        "typeArguments": null
      },
      "attributes": [
        {
          "name": "storage",
          "arguments": [
            "read",
            "write"
          ]
        }
      ]
    }
  ],
  "loggedTypes": [
    {
      "logId": 0,
      "loggedType": {
        "name": "",
        "type": 6,
        "typeArguments": []
      }
    },
    {
      "logId": 1,
      "loggedType": {
        "name": "",
        "type": 3,
        "typeArguments": []
      }
    },
    {
      "logId": 2,
      "loggedType": {
        "name": "",
        "type": 3,
        "typeArguments": []
      }
    },
    {
      "logId": 3,
      "loggedType": {
        "name": "",
        "type": 17,
        "typeArguments": []
      }
    },
    {
      "logId": 4,
      "loggedType": {
        "name": "",
        "type": 3,
        "typeArguments": []
      }
    },
    {
      "logId": 5,
      "loggedType": {
        "name": "",
        "type": 6,
        "typeArguments": []
      }
    },
    {
      "logId": 6,
      "loggedType": {
        "name": "",
        "type": 13,
        "typeArguments": []
      }
    },
    {
      "logId": 7,
      "loggedType": {
        "name": "",
        "type": 3,
        "typeArguments": []
      }
    },
    {
      "logId": 8,
      "loggedType": {
        "name": "",
        "type": 3,
        "typeArguments": []
      }
    },
    {
      "logId": 9,
      "loggedType": {
        "name": "",
        "type": 19,
        "typeArguments": []
      }
    },
    {
      "logId": 10,
      "loggedType": {
        "name": "",
        "type": 6,
        "typeArguments": []
      }
    },
    {
      "logId": 11,
      "loggedType": {
        "name": "",
        "type": 6,
        "typeArguments": []
      }
    },
    {
      "logId": 12,
      "loggedType": {
        "name": "",
        "type": 6,
        "typeArguments": []
      }
    },
    {
      "logId": 13,
      "loggedType": {
        "name": "",
        "type": 3,
        "typeArguments": []
      }
    },
    {
      "logId": 14,
      "loggedType": {
        "name": "",
        "type": 6,
        "typeArguments": []
      }
    },
    {
      "logId": 15,
      "loggedType": {
        "name": "",
        "type": 14,
        "typeArguments": []
      }
    },
    {
      "logId": 16,
      "loggedType": {
        "name": "",
        "type": 5,
        "typeArguments": []
      }
    },
    {
      "logId": 17,
      "loggedType": {
        "name": "",
        "type": 3,
        "typeArguments": []
      }
    },
    {
      "logId": 18,
      "loggedType": {
        "name": "",
        "type": 3,
        "typeArguments": []
      }
    },
    {
      "logId": 19,
      "loggedType": {
        "name": "",
        "type": 15,
        "typeArguments": []
      }
    },
    {
      "logId": 20,
      "loggedType": {
        "name": "",
        "type": 6,
        "typeArguments": []
      }
    },
    {
      "logId": 21,
      "loggedType": {
        "name": "",
        "type": 3,
        "typeArguments": []
      }
    },
    {
      "logId": 22,
      "loggedType": {
        "name": "",
        "type": 16,
        "typeArguments": []
      }
    },
    {
      "logId": 23,
      "loggedType": {
        "name": "",
        "type": 6,
        "typeArguments": []
      }
    },
    {
      "logId": 24,
      "loggedType": {
        "name": "",
        "type": 6,
        "typeArguments": []
      }
    },
    {
      "logId": 25,
      "loggedType": {
        "name": "",
        "type": 18,
        "typeArguments": []
      }
    },
    {
      "logId": 26,
      "loggedType": {
        "name": "",
        "type": 3,
        "typeArguments": []
      }
    },
    {
      "logId": 27,
      "loggedType": {
        "name": "",
        "type": 10,
        "typeArguments": []
      }
    },
    {
      "logId": 28,
      "loggedType": {
        "name": "",
        "type": 3,
        "typeArguments": []
      }
    },
    {
      "logId": 29,
      "loggedType": {
        "name": "",
        "type": 6,
        "typeArguments": []
      }
    },
    {
      "logId": 30,
      "loggedType": {
        "name": "",
        "type": 3,
        "typeArguments": []
      }
    },
    {
      "logId": 31,
      "loggedType": {
        "name": "",
        "type": 6,
        "typeArguments": []
      }
    },
    {
      "logId": 32,
      "loggedType": {
        "name": "",
        "type": 3,
        "typeArguments": []
      }
    },
    {
      "logId": 33,
      "loggedType": {
        "name": "",
        "type": 3,
        "typeArguments": []
      }
    },
    {
      "logId": 34,
      "loggedType": {
        "name": "",
        "type": 3,
        "typeArguments": []
      }
    },
    {
      "logId": 35,
      "loggedType": {
        "name": "",
        "type": 6,
        "typeArguments": []
      }
    }
  ],
  "messagesTypes": [],
  "configurables": []
}

export class NftMarketplaceAbi__factory {
  static readonly abi = _abi
  static createInterface(): NftMarketplaceAbiInterface {
    return new Interface(_abi) as unknown as NftMarketplaceAbiInterface
  }
  static connect(
    id: string | AbstractAddress,
    accountOrProvider: Account | Provider
  ): NftMarketplaceAbi {
    return new Contract(id, _abi, accountOrProvider) as unknown as NftMarketplaceAbi
  }
}

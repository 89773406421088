import React from "react";
import HeaderStyle2 from "../components/header/HeaderStyle2";
import Footer from "../components/footer/Footer";
import SliderStyle3 from "../components/slider/SliderStyle3";
import heroSliderData from "../assets/fake-data/data-slider";
import LiveAuction from "../components/layouts/home/LiveAuction";
import TopCollections from "../components/layouts/home/TopCollections";
import TodayPicks from "../components/layouts/home-6/TodayPicks";
import todayPickData from "../assets/fake-data/data-today-pick";
import LaunchpadDrops from "../components/layouts/home/LaunchpadDrops";
import Create from "../components/layouts/home-6/Create";
import AllNFTs from "../components/layouts/AllNFTs";
import Slider2 from "../components/slider/Slider";
import { Slider3 } from "../components/slider/Slider3";
import liveAuctionData from "../assets/fake-data/data-live-auction";

const Home = () => {
  return (
    <div className="home-1">
      {/* <HeaderStyle2 /> */}
      {/* <SliderStyle3 /> */}
      <Slider3 data={heroSliderData} />
      {/* <Slider2 data={heroSliderData} /> */}
      <TopCollections />
      {/* <LiveAuction data={liveAuctionData} /> */}
      {/* <AllNFTs />  */}
      {/* <TodayPicks data={todayPickData} /> */}
      <LaunchpadDrops />
      <Create />
    </div>
  );
};

export default Home;

import img1 from "../images/box-item/card-item-3.jpg";
import imga1 from "../images/avatar/avt-1.jpg";
import img2 from "../images/box-item/card-item-4.jpg";
import imga2 from "../images/avatar/avt-2.jpg";
import img3 from "../images/box-item/card-item-2.jpg";
import imga3 from "../images/avatar/avt-4.jpg";
import img4 from "../images/box-item/card-item-7.jpg";
import imga4 from "../images/avatar/avt-3.jpg";
import img5 from "../images/box-item/card-item8.jpg";
import imga5 from "../images/avatar/avt-12.jpg";
import img6 from "../images/box-item/card-item-9.jpg";
import imga6 from "../images/avatar/avt-1.jpg";
import img7 from "../images/box-item/image-box-6.jpg";
import imga7 from "../images/avatar/avt-4.jpg";
import img8 from "../images/box-item/image-box-11.jpg";
import imga8 from "../images/avatar/avt-3.jpg";
import imgCollection1 from "../images/avatar/avt-18.jpg";

const todayPickData = [
  {
    contract_id:
      "0xc72f4e3715e5e3cd7f58dfcf1236fab96f9f5ef8e2e60c668b375778f3c9064e",
    token: 1,
    name: "The RenaiXance Rising the sun",
    img: img1,
    desc: "this is a test",
    owner: "me",
    price: "4.89 ETH",
  },
  {
    contract_id:
      "0xc72f4e3715e5e3cd7f58dfcf1236fab96f9f5ef8e2e60c668b375778f3c9064e",
    token: 2,
    name: "The RenaiXance Rising the sun",
    img: img2,
    desc: "this is a test",
    owner: "me",
    price: "4.89 ETH",
  },
  {
    contract_id:
      "0xc72f4e3715e5e3cd7f58dfcf1236fab96f9f5ef8e2e60c668b375778f3c9064e",
    token: 3,
    name: "The RenaiXance Rising the sun",
    img: img3,
    desc: "this is a test",
    owner: "me",
    price: "4.89 ETH",
  },
  {
    contract_id:
      "0xc72f4e3715e5e3cd7f58dfcf1236fab96f9f5ef8e2e60c668b375778f3c9064e",
    token: 4,
    name: "The RenaiXance Rising the sun",
    img: img4,
    desc: "this is a test",
    owner: "me",
    price: "4.89 ETH",
  },
  {
    contract_id:
      "0xc72f4e3715e5e3cd7f58dfcf1236fab96f9f5ef8e2e60c668b375778f3c9064e",
    token: 5,
    name: "The RenaiXance Rising the sun",
    img: img5,
    desc: "this is a test",
    owner: "me",
    price: "4.89 ETH",
  },
];
export const todayPickData1 = [
  {
    id: 1,
    img: img1,
    title: "The RenaiXance Rising the sun ",
    tags: "bsc",
    imgAuthor: imga1,
    nameAuthor: "SalvadorDali",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D",
  },
  {
    id: 2,
    img: img2,
    title: "Space babe - Night 2/25 ",
    tags: "bsc",
    imgAuthor: imga2,
    nameAuthor: "Trista Francis",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    feature: "coming soon",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D",
  },
  {
    id: 3,
    img: img3,
    title: "CyberPrimal 042 LAN",
    tags: "bsc",
    imgAuthor: imga3,
    nameAuthor: "Freddie Carpenter",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D",
  },
  {
    id: 4,
    img: img4,
    title: "Crypto Egg Stamp #5 ",
    tags: "bsc",
    imgAuthor: imga4,
    nameAuthor: "Tyler Covington",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D",
  },
  {
    id: 5,
    img: img5,
    title: "Travel Monkey Club #45 ",
    tags: "bsc",
    imgAuthor: imga5,
    nameAuthor: "SalvadorDali",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D",
  },
  {
    id: 6,
    img: img6,
    title: "Sir. Lion Swag #371 ",
    tags: "bsc",
    imgAuthor: imga6,
    nameAuthor: "Trista Francis",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D",
  },
  {
    id: 7,
    img: img7,
    title: "Cyber Doberman #766",
    tags: "bsc",
    imgAuthor: imga7,
    nameAuthor: "Freddie Carpenter",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D",
  },
  {
    id: 8,
    img: img8,
    title: "Living Vase 01 by Lanz...",
    tags: "bsc",
    imgAuthor: imga8,
    nameAuthor: "Freddie Carpenter",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D",
  },
  {
    id: 9,
    img: img1,
    title: "The RenaiXance Rising the sun ",
    tags: "bsc",
    imgAuthor: imga1,
    nameAuthor: "SalvadorDali",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D",
  },
  {
    id: 10,
    img: img2,
    title: "Space babe - Night 2/25 ",
    tags: "bsc",
    imgAuthor: imga2,
    nameAuthor: "Trista Francis",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    feature: "coming soon",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D",
  },
  {
    id: 11,
    img: img3,
    title: "CyberPrimal 042 LAN",
    tags: "bsc",
    imgAuthor: imga3,
    nameAuthor: "Freddie Carpenter",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D",
  },
  {
    id: 12,
    img: img4,
    title: "Crypto Egg Stamp #5 ",
    tags: "bsc",
    imgAuthor: imga4,
    nameAuthor: "Tyler Covington",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D",
  },
  {
    id: 13,
    img: img5,
    title: "Travel Monkey Club #45 ",
    tags: "bsc",
    imgAuthor: imga5,
    nameAuthor: "SalvadorDali",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D",
  },
  {
    id: 14,
    img: img6,
    title: "Sir. Lion Swag #371 ",
    tags: "bsc",
    imgAuthor: imga6,
    nameAuthor: "Trista Francis",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D",
  },
  {
    id: 15,
    img: img7,
    title: "Cyber Doberman #766",
    tags: "bsc",
    imgAuthor: imga7,
    nameAuthor: "Freddie Carpenter",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D",
  },
  {
    id: 16,
    img: img8,
    title: "Living Vase 01 by Lanz...",
    tags: "bsc",
    imgAuthor: imga8,
    nameAuthor: "Freddie Carpenter",
    price: "4.89 ETH",
    priceChange: "$12.246",
    wishlist: "100",
    imgCollection: imgCollection1,
    nameCollection: "Creative Art 3D",
  },
];

export default todayPickData;

import React, {
  useRef,
  useState,
  useEffect,
  Fragment,
  useCallback,
} from "react";
import { Link, useLocation } from "react-router-dom";
import menus from "../../pages/menu";
import DarkMode from "./DarkMode";
import logoheader from "../../assets/images/logo/logo.png";
import logoheader2x from "../../assets/images/logo/logo@2x.png";
import logodark from "../../assets/images/logo/logo_dark.png";
import logodark2x from "../../assets/images/logo/logo_dark@2x.png";
import imgsun from "../../assets/images/icon/sun.png";
import avt from "../../assets/images/avatar/avt-2.jpg";
import { Address } from "fuels";
import Modal from "react-bootstrap/Modal";
import { token } from "../../utils/auth";
import UAuth from "@uauth/js";

const SIGN_MESSAGE = "This is a test for fuelart";

const Header = () => {
  const [walletDropdownMenu, setWalletDropdownMenu] = useState(false);
  const [showModal, setShowModal] = useState();
  const [address, setAddress] = useState();
  const { pathname } = useLocation();

  const uauth = new UAuth({
    clientID: "7737614c-22ac-4413-96c0-9ac46d325b5d",
    // redirectUri: "https://fuelart.vercel.app",
    // redirectUri: "http://localhost:3000",
    redirectUri: "https://fuelart.io",
    scope: "openid wallet",
  });

  const connectWallet = useCallback(async () => {
    const w = window;
    const add = localStorage.getItem("publicKey");
    // if (add) {
    //   setAddress(Address.fromAddressOrString(add).toB256());
    // }
    if (!token) {
      const isConnected = await w.fuel.connect();
      console.log("Connection response", isConnected);
      const accounts = await w.fuel.accounts();
      console.log(accounts);
      localStorage.setItem("publicKey", accounts[0]);
      setAddress(Address.fromAddressOrString(accounts[0]).toB256());
      setShowModal(true);
    } else {
      const accounts = await window.fuel.accounts();
      localStorage.setItem("publicKey", accounts[0]);
      setAddress(Address.fromAddressOrString(accounts[0]).toB256());
    }
  }, []);

  const connectWalletButton = async () => {
    const w = window;
    try {
      const accounts = await window.fuel.accounts();
      localStorage.setItem("publicKey", accounts[0]);
      setAddress(Address.fromAddressOrString(accounts[0]).toB256());
    } catch (error) {
      const isConnected = await w.fuel.connect();
      console.log("Connection response", isConnected);
      const accounts = await w.fuel.accounts();
      localStorage.setItem("publicKey", accounts[0]);
      setAddress(Address.fromAddressOrString(accounts[0]).toB256());
      setShowModal(true);
    }
  };

  const signMessage = async () => {
    let w = window;
    if (w.fuel) {
      const accounts = await w.fuel.accounts();
      const signature = await w.fuel.signMessage(accounts[0], SIGN_MESSAGE);
      if (signature && address) {
        const body = {
          // alert("Please install fuel wallet!");
          varifiedBy: "fuel",
          address,
          signature,
          message: SIGN_MESSAGE,
        };
        try {
          const response = await fetch(
            `${process.env.REACT_APP_AMRKETPLACE_API_URL}/user/auth`,
            {
              method: "POST",
              headers: {
                "Content-type": "application/json",
              },
              body: JSON.stringify(body),
            }
          );
          if (response.ok) {
            const data = await response.json();
            localStorage.setItem("token", data.token);
            setShowModal(false);
          } else {
            handleModelClose();
          }
        } catch (error) {
          handleModelClose();
          console.error(error);
        }
      }
    }
  };

  useEffect(() => {
    connectWallet();
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [connectWallet]);

  const headerRef = useRef(null);
  const menuLeft = useRef(null);
  const btnToggle = useRef(null);
  const btnSearch = useRef(null);

  const isSticky = (e) => {
    const header = document.querySelector(".js-header");
    const scrollTop = window.scrollY;
    scrollTop >= 300
      ? header.classList.add("is-fixed")
      : header.classList.remove("is-fixed");
    scrollTop >= 400
      ? header.classList.add("is-small")
      : header.classList.remove("is-small");
  };

  const menuToggle = () => {
    menuLeft.current.classList.toggle("active");
    btnToggle.current.classList.toggle("active");
  };

  // const searchBtn = () => {
  //   btnSearch.current.classList.toggle("active");
  // };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleOnClick = (index) => {
    setActiveIndex(index);
  };

  const handleWalletClick = (e) => {
    e.stopPropagation();
    setWalletDropdownMenu((prev) => !prev);
  };

  document.addEventListener("click", (e) => {
    e.stopPropagation();
    setWalletDropdownMenu(false);
  });

  const disconnectWallet = async () => {
    const isConnected = await window.fuel.disconnect();
    console.log(isConnected);
    setAddress(null);
    // localStorage.removeItem("publicKey");
    // expect(isConnected).toBeFalsy();
  };

  const handleModelClose = () => {
    disconnectWallet();
    setShowModal(false);
  };

  // const loginWithUD = () => {
  //   uauth.loginWithPopup().then(async (auth) => {
  //     const body = {
  //       varifiedBy: "eth",
  //       address: address,
  //       eth_address: auth.idToken.wallet_address,
  //       signature: auth.idToken.eip4361_signature,
  //       message: auth.idToken.eip4361_message,
  //     };
  //     try {
  //       const response = await fetch(
  //         `${process.env.REACT_APP_AMRKETPLACE_API_URL}/user/auth`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-type": "application/json",
  //           },
  //           body: JSON.stringify(body),
  //         }
  //       );
  //       if (response.ok) {
  //         const data = await response.json();
  //         localStorage.setItem("token", data.token);
  //         localStorage.setItem("fuelart-ud", auth.idToken.sub);
  //         setShowModal(false);
  //       } else {
  //         handleModelClose();
  //       }
  //     } catch (error) {
  //       handleModelClose();
  //       console.error(error);
  //     }
  //     // setShowModal(false);
  //   });
  // };

  const WalletModal = (
    <Modal
      onHide={handleModelClose}
      show={showModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3 className="text-center">Verify Wallet</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="changeListing">
          <div>
            <h5>
              Verify wallet to prove ownership, receive rewards and lower fees.
              No FUEL is charged.
            </h5>

            <div className="d-flex justify-content-center mt-4 actions">
              <button
                className="sc-button fl-button style-1"
                onClick={signMessage}
              >
                <span>Verify Wallet</span>
              </button>

              {/* <button
                className="sc-button fl-button style-1"
                onClick={loginWithUD}
              >
                <span className="d-flex align-items-center">
                  Login with{" "}
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="css-1bncsgz-logo"
                  >
                    <path
                      d="M43.4917 5.14258V19.9488L0 37.4439L43.4917 5.14258Z"
                      fill="#00C9FF"
                    ></path>
                    <path
                      d="M35.3383 4.5V30.0417C35.3383 33.611 33.9063 37.0342 31.3573 39.5581C28.8083 42.0821 25.3511 43.5 21.7463 43.5C18.1415 43.5 14.6843 42.0821 12.1353 39.5581C9.58631 37.0342 8.1543 33.611 8.1543 30.0417V19.2791L16.3081 14.8359V30.0417C16.2318 30.7053 16.2979 31.3774 16.5021 32.014C16.7064 32.6506 17.0441 33.2372 17.4933 33.7355C17.9425 34.2338 18.4929 34.6325 19.1085 34.9055C19.7242 35.1785 20.3911 35.3197 21.0657 35.3197C21.7402 35.3197 22.4072 35.1785 23.0228 34.9055C23.6384 34.6325 24.1889 34.2338 24.638 33.7355C25.0872 33.2372 25.425 32.6506 25.6292 32.014C25.8335 31.3774 25.8996 30.7053 25.8232 30.0417V9.65439L35.3383 4.5Z"
                      fill="#0D67FE"
                    ></path>
                  </svg>
                </span>
              </button> */}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );

  return (
    <Fragment>
      <div className="accounement">
        <p>
          Have a collection to launch - &nbsp;
          <a
            href="https://discord.gg/BGk5UghWWk"
            target="_blank"
            rel="noopener noreferrer"
          >
            join now
          </a>
          <br />
          This is still in beta phase. so please cooperate.
        </p>
      </div>
      <header id="header_main" className="header_1 js-header" ref={headerRef}>
        <div className="themesflat-container">
          {WalletModal}
          <div className="row">
            <div className="col-md-12">
              <div id="site-header-inner">
                <div className="wrap-box flex">
                  <div id="site-logo" className="clearfix">
                    <div id="site-logo-inner">
                      <Link to="/" rel="home" className="main-logo">
                        <img
                          className="logo-dark"
                          id="logo_header"
                          src={logodark}
                          srcSet={`${logodark2x}`}
                          alt="nft-gaming"
                        />
                        <img
                          className="logo-light"
                          id="logo_header"
                          src={logoheader}
                          srcSet={logoheader2x}
                          alt="nft-gaming"
                        />
                      </Link>
                    </div>
                  </div>
                  <div
                    className="mobile-button"
                    ref={btnToggle}
                    onClick={menuToggle}
                  >
                    <span></span>
                  </div>
                  <nav id="main-nav" className="main-nav ms" ref={menuLeft}>
                    <ul id="menu-primary-menu" className="menu">
                      {menus.map((data, index) => (
                        <li
                          key={index}
                          onClick={() => handleOnClick(index)}
                          className={`menu-item ${
                            data.namesub ? "menu-item-has-children" : ""
                          } ${activeIndex === index ? "active" : ""} `}
                        >
                          <Link to={data.links}>{data.name}</Link>
                          {data.namesub && (
                            <ul className="sub-menu">
                              {data.namesub.map((submenu) => (
                                <li
                                  key={submenu.id}
                                  className={
                                    pathname === submenu.links
                                      ? "menu-item current-item"
                                      : "menu-item"
                                  }
                                >
                                  <Link to={submenu.links}>{submenu.sub}</Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                  <div className="flat-search-btn flex">
                    <div className="header-search flat-show-search" id="s1">
                      {/* <Link
                      to="#"
                      className="show-search header-search-trigger"
                      onClick={searchBtn}
                    >
                      <i className="far fa-search"></i>
                    </Link> */}
                      <div className="top-search" ref={btnSearch}>
                        <form
                          action="#"
                          method="get"
                          role="search"
                          className="search-form"
                        >
                          <input
                            type="search"
                            id="s"
                            className="search-field"
                            placeholder="Search..."
                            name="s"
                            title="Search for"
                            required=""
                          />
                          <button
                            className="search search-submit"
                            type="submit"
                            title="Search"
                          >
                            <i className="icon-fl-search-filled"></i>
                          </button>
                        </form>
                      </div>
                    </div>
                    <div className="sc-btn-top mg-r-12" id="site-header">
                      {address ? (
                        <Fragment>
                          <button
                            onClick={handleWalletClick}
                            className="sc-button header-slider style style-1 wallet fl-button pri-1 wallet-button truncate max-w-230"
                          >
                            <span>{address}</span>
                          </button>
                          {walletDropdownMenu ? (
                            <div className="walletDropdownMenu">
                              <Link
                                to="/author"
                                rel="home"
                                className="main-logo"
                              >
                                <div className="menuItem">
                                  <span>Profile</span>
                                </div>
                              </Link>
                              <Link
                                to="/edit-profile"
                                rel="home"
                                className="main-logo"
                              >
                                <div className="menuItem">
                                  <span>Edit Profile</span>
                                </div>
                              </Link>
                              <Link
                                onClick={disconnectWallet}
                                className="main-logo"
                              >
                                <div className="menuItem">
                                  <span>Disconnect</span>
                                </div>
                              </Link>
                            </div>
                          ) : (
                            <></>
                          )}
                        </Fragment>
                      ) : (
                        <button
                          onClick={() => {
                            connectWalletButton();
                          }}
                          className="sc-button header-slider style style-1 wallet fl-button pri-1"
                        >
                          <span>Wallet Connect</span>
                        </button>
                      )}
                    </div>

                    <div className="admin_active" id="header_admin">
                      <div className="header_avatar">
                        <div className="price">
                          <span>
                            2.45 <strong>ETH</strong>{" "}
                          </span>
                        </div>
                        <img className="avatar" src={avt} alt="avatar" />
                        <div className="avatar_popup mt-20">
                          <div className="d-flex align-items-center copy-text justify-content-between">
                            <span> 13b9ebda035r178... </span>
                            <Link to="/" className="ml-2">
                              <i className="fal fa-copy"></i>
                            </Link>
                          </div>
                          <div className="d-flex align-items-center mt-10">
                            <img className="coin" src={imgsun} alt="/" />
                            <div className="info ml-10">
                              <p className="text-sm font-book text-gray-400">
                                Balance
                              </p>
                              <p className="w-full text-sm font-bold text-green-500">
                                16.58 ETH
                              </p>
                            </div>
                          </div>
                          <div className="hr"></div>
                          <div className="links mt-20">
                            <Link to="#">
                              <i className="fab fa-accusoft"></i>{" "}
                              <span> My items</span>
                            </Link>
                            <a className="mt-10" href="/edit-profile">
                              <i className="fas fa-pencil-alt"></i>{" "}
                              <span> Edit Profile</span>
                            </a>
                            <a className="mt-10" href="/login" id="logout">
                              <i className="fal fa-sign-out"></i>{" "}
                              <span> Logout</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DarkMode />
      </header>
    </Fragment>
  );
};

export default Header;

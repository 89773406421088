import { useState, useEffect } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import "react-tabs/style/react-tabs.css";
import liveAuctionData from "../assets/fake-data/data-live-auction";
import LiveAuction from "../components/layouts/LiveAuction";
import img1 from "../assets/images/test/anger.webp";
import { Link, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useDispatch } from "react-redux";
import { showLoader } from "../store/loaderSlice";
import { LaunchpadAbi__factory } from "../contracts/launchpad/factories/LaunchpadAbi__factory";
import { SuccessFullyMinted } from "../popups/SuccessFullyMinted";

import {
  LaunchpadContract,
  getWallet,
  getPublicKey,
} from "./../utils/GetContract";
import { LoadingDots } from "../components/LoadingDots";
const socialList = [
  {
    icon: "fab fa-twitter",
    link: "#",
  },
  {
    icon: "fab fa-facebook",
    link: "#",
  },
  {
    icon: "fab fa-telegram-plane",
    link: "#",
  },
  {
    icon: "fab fa-youtube",
    link: "#",
  },
  {
    icon: "icon-fl-tik-tok-2",
    link: "#",
  },
  {
    icon: "icon-fl-vt",
    link: "#",
  },
];

const list = [
  { id: 1, name: "some name", description: "this is test", imgUrl: img1 },
  { id: 2, name: "some name", description: "this is test", imgUrl: img1 },
  { id: 3, name: "some name", description: "this is test", imgUrl: img1 },
  { id: 4, name: "some name", description: "this is test", imgUrl: img1 },
  { id: 5, name: "some name", description: "this is test", imgUrl: img1 },
];

export const LaunchpadProject = () => {
  const [state, setState] = useState({ list: list });
  const [price, setPrice] = useState();
  const [totalSupply, setTotalSupply] = useState();
  const [maxSupply, setMaxSuuply] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [publicKey, setPublicKey] = useState();
  const [contractCall, setContractCall] = useState(false);

  const dispatch = useDispatch();

  const [mintValue, setMinValue] = useState(1);

  const { contract_id } = useParams();

  // const contractId =
  //   "0x5d4f41b5e6249cca9d0a7c0af4a84ad802de75076a42e8af538eef76f0900729";

  const contractInstance = async () => {
    const wallet = await getWallet();
    // return new Contract(contract_id, _abi, wallet);
    return LaunchpadAbi__factory.connect(contract_id, wallet);
  };
  const total_supply = async () => {
    const total_supply = await LaunchpadContract(contract_id)
      .functions.total_supply()
      .get();
    setTotalSupply(total_supply.value.toNumber());
    // console.log("total_supply:- ", total_supply.value);
  };

  const max_supply = async () => {
    const max_supply = await LaunchpadContract(contract_id)
      .functions.max_supply()
      .get();
    setMaxSuuply(max_supply.value.toNumber());
    // console.log("max_supply:- ", max_supply.value);
  };

  const mintPrice = async () => {
    const mint_price = await LaunchpadContract(contract_id)
      .functions.mint_price()
      .get();
    setPrice(mint_price.value.toNumber() / 1e9);
    // console.log("mint_price: ", mint_price);
  };

  useEffect(() => {
    mintPrice();
    total_supply();
    max_supply();
  }, []);

  const handleClose = () => setModalShow(false);

  const loadData = async (token) => {
    const contract = await contractInstance();
    const metaData = await contract.functions.meta_data(token).get();
    const res = await fetch(metaData.value.token_uri);
    const data = await res.json();
    const url = data.image;

    setState({
      list: [{ imgUrl: url, name: data.name, description: data.description }],
    });
  };

  const mint = async () => {
    setContractCall(true);
    try {
      const publicKey = await getPublicKey();
      setPublicKey(publicKey);
      const contract = await contractInstance();
      const mintedNFT = await contract.functions
        .mint(1, {
          Address: { value: publicKey },
        })
        .txParams({ gasPrice: 1 })
        .callParams({
          forward: [price * 1e9],
        })
        // .simulate();
        .call();
      console.log("mint", mintedNFT.logs[0].token_id.toNumber());

      total_supply();
      max_supply();

      await loadData(mintedNFT.logs[0].token_id.toNumber());
      setContractCall(false);
      // setModalShow(true);
    } catch (error) {
    } finally {
      setContractCall(false);
    }

    // alert("Congratulations on your fuel nft!");
  };

  const decreaseMintValue = () => {
    setMinValue((prev) => (prev > 1 ? prev - 1 : 1));
  };

  const increaseMintValue = () => {
    setMinValue((prev) => (prev < 5 ? prev + 1 : 5));
  };

  return (
    <div className="item-details">
      {/* <Header /> */}
      {contractCall ? <LoadingDots fullScreen={true} /> : <></>}
      <section className="tf-item-details tf-section">
        <div className="themesflat-container mt-5">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <h2 className="tf-title-heading style-2 mg-bt-12">
                Fuel Nft Collection: First one <br />
              </h2>
              <div className="widget-social style-1 mg-t32">
                <ul>
                  {socialList.map((item, index) => (
                    <li key={index}>
                      <Link to={item.link}>
                        <i className={item.icon}></i>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <h5 className="sub-title style-1">
                This is a demo project. <br />
                <a href="#">Read Now!</a>
              </h5>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="content-left">
                <div className="media">
                  <img src={img1} alt="Fuelart" />
                </div>
                <SuccessFullyMinted
                  show={modalShow}
                  onHide={handleClose}
                  list={state.list}
                  publicKey={publicKey}
                />
                <div className="content-right pl-0">
                  <div className="sc-item-details">
                    <div className="meta-item-details style2">
                      <div
                        style={{ width: "100%" }}
                        className="item meta-price"
                      >
                        <span className="heading">Total minted</span>
                        <div className="price">
                          <div className="price-box">
                            <h5>
                              {totalSupply}/{maxSupply}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="meta-item-details style2">
                      <div
                        style={{ width: "100%" }}
                        className="item meta-price d-block"
                      >
                        <div className="widget widget-search mgbt-24 d-flex justify-content-between align-items-center">
                          <div className="price">
                            <div className="price-box">
                              <h5>Price : {price} ETH</h5>
                            </div>
                          </div>
                          <div className="incDec">
                            <span
                              onClick={decreaseMintValue}
                              className="decrement"
                            >
                              -
                            </span>
                            <span className="value">{mintValue}</span>
                            <span
                              onClick={increaseMintValue}
                              className="increment"
                            >
                              +
                            </span>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-4">
                          <button
                            onClick={mint}
                            className="sc-button loadmore fl-button pri-3 mb-0"
                          >
                            <span>
                              {/* {publicKey ? "Mint" : "Connect your wallet"} */}
                              Mint
                            </span>
                          </button>
                          <Link
                            to="#"
                            id="load-more"
                            className="sc-button loadmore fl-button pri-3 mb-0"
                          >
                            <span>See Collection</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="divider"></div>
      <section className="tf-item-details tf-section pb-4">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12 col-md-6">
              <h2 className="tf-title-heading style-2 mg-bt-12">
                Fuel Nft Collection: First one <br />
              </h2>
              {/* <div className="widget-social style-1 mg-t32">
                <ul>
                  {socialList.map((item, index) => (
                    <li key={index}>
                      <Link to={item.link}>
                        <i className={item.icon}></i>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div> */}
              <div>
                <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cum
                  labore, repellendus facilis perferendis, amet reprehenderit id
                  sunt blanditiis temporibus recusandae saepe, iste delectus eum
                  unde totam minus quam. Vitae deserunt ullam dolor ad, totam
                  asperiores velit quia fugiat suscipit atque, officia earum
                  voluptatibus adipisci animi? Pariatur beatae consequuntur ex
                  ipsam.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="create-item">
                <div className="tf-create-item tf-section">
                  <div className="themesflat-container">
                    <div className="form-create-item">
                      <div className="flat-tabs tab-create-item">
                        <Tabs>
                          <TabList className="tabList">
                            <Tab>Roadmap</Tab>
                            <Tab>Team</Tab>
                          </TabList>
                          <TabPanel>
                            <p>
                              Lorem ipsum, dolor sit amet consectetur
                              adipisicing elit. Soluta, odit quaerat nisi enim
                              illo molestias. Officia delectus neque molestiae
                              inventore ipsam quis accusamus quae odio aliquam
                              libero omnis fuga, velit dolores hic. Expedita,
                              quos.
                            </p>
                          </TabPanel>
                          <TabPanel>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Ea dignissimos deleniti magnam eaque autem
                              exercitationem ipsa placeat eos necessitatibus,
                              beatae id doloremque? Facere maxime, temporibus
                              aut provident eius corporis quibusdam voluptate
                              aspernatur sequi? Obcaecati.
                            </p>
                          </TabPanel>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="pb-5 tf-section tf-item-details">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="content-left">
                <div className="media">
                  <img src={img1} alt="Fuelart" />
                </div>
              </div>
            </div>
            <MyModal show={modalShow} onHide={handleClose} />
            <div className=" col-md-6 col-12">
              {name ? (
                <div className="content-right">
                  <div className="sc-item-details">
                    <h2 className="style2">"Project Name"</h2>
                    <p>Project description</p>
                    <div className="meta-item-details style2">
                      <div className="item meta-price">
                        <span className="heading">Total minted</span>
                        <div className="price">
                          <div className="price-box">
                            <h5>
                              {totalSupply}/{maxSupply}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="meta-item-details style2">
                      <div className="item meta-price">
                        <span className="heading">Price</span>
                        <div className="price">
                          <div className="price-box">
                            <h5> {price} ETH</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="sc-button loadmore style bag fl-button pri-3 disabled"
                      onClick={mint}
                    >
                      <span>Mint</span>
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div> */}
      {/* <LiveAuction data={liveAuctionData} /> */}
      {/* <Footer /> */}
    </div>
  );
};

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import img1 from "../../../assets/images/avatar/avt-31.jpg";
import img2 from "../../../assets/images/avatar/avata_profile.jpg";
import img3 from "../../../assets/images/avatar/avt-4.jpg";
import img4 from "../../../assets/images/avatar/avt-3.jpg";
import img5 from "../../../assets/images/avatar/avt-5.jpg";
import img6 from "../../../assets/images/avatar/avt-8.jpg";
import img7 from "../../../assets/images/avatar/avt-6.jpg";
import img8 from "../../../assets/images/avatar/avt-9.jpg";
import img9 from "../../../assets/images/avatar/avt-7.jpg";
import img10 from "../../../assets/images/avatar/avt-10.jpg";
import img11 from "../../../assets/images/avatar/avt-32.jpg";
import img12 from "../../../assets/images/avatar/avt-33.jpg";

import test from "../../../assets/images/test/anger.webp";

const TopCollections = () => {
  const [dataTopSellerTab] = useState([
    {
      title: "24 Hours",
    },
    {
      title: "Week",
    },
    {
      title: "Month",
    },
  ]);
  const [dataTopSellerPanel] = useState([
    {
      id: 1,
      dataTopSellerContent: [
        {
          img: test,
          name: "Test Collection",
          mail: "@windsorlandhh",
          top: "1",
          // price: "Floor:- 120.7 ETH",
          contract:
            "0x9ca97176d24ae2f0461b9176ef5385b56f3f2c693d590919a8501046f1e4aea8",
        },

        {
          img: test,
          name: "Public Mint Collection",
          mail: "@windsorlandhh",
          top: "2",
          // price: "Floor:- 120.7 ETH",
          contract:
            "0xc432d990e1288eea41fbb961459a00a351ce38176b586b3855777040ffa59f85",
        },

        {
          img: test,
          name: "Public Mint 2 Collection",
          mail: "@windsorlandhh",
          top: "2",
          // price: "Floor:- 120.7 ETH",
          contract:
            "0x1c7dbaca52829ed792d0e3fd8a5fe5ba196c094284110a717c7f6ffe24b18ae6",
        },
        {
          img: test,
          name: "Launchpad Mint Collection",
          mail: "@windsorlandhh",
          top: "3",
          // price: "Floor:- 120.7 ETH",
          contract:
            "0x5d4f41b5e6249cca9d0a7c0af4a84ad802de75076a42e8af538eef76f0900729",
        },
        // {
        //   img: test,
        //   name: "Test Collection",
        //   mail: "@windsorlandhh",
        //   top: "4",
        //   // price: "120.7 ETH",
        //   contract:
        //     "0x9ca97176d24ae2f0461b9176ef5385b56f3f2c693d590919a8501046f1e4aea8",
        // },
      ],
    },
  ]);
  return (
    <div id="top-collections">
      <section className="tf-section top-seller home5 bg-style">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="heading-live-auctions">
                <h2 className="tf-title pb-16">Top Collections</h2>
                {/* <a href="/explore-03" className="exp style2 see-all">
                  SEE ALL
                </a> */}
              </div>
              <div className="flat-tabs seller-tab">
                <Tabs>
                  {/* <TabList>
                    {dataTopSellerTab.map((item, index) => (
                      <Tab key={index}>{item.title}</Tab>
                    ))}
                  </TabList> */}

                  <div className="content-tab mg-t-24">
                    {dataTopSellerPanel.map((item) => (
                      <TabPanel key={item.id}>
                        {item.dataTopSellerContent.map((item, index) => (
                          <div
                            key={index}
                            className="col-xl-3 col-lg-4 col-md-6"
                          >
                            <Link to={`/collection/${item.contract}`}>
                              <div className="box-item">
                                <div className="sc-author-box style-3">
                                  <div className="author-style2 flex">
                                    <div className="author-avatar">
                                      <img
                                        src={item.img}
                                        alt="Fuelart"
                                        className="avatar"
                                      />
                                      <div className="badge">
                                        <i className="ripple"></i>
                                      </div>
                                    </div>
                                    <div className="author-infor">
                                      <h5>
                                        <Link
                                          to={`/collection/${item.contract}`}
                                        >
                                          {item.name}
                                        </Link>
                                      </h5>
                                      {/* <div className="tag">{item.mail}</div> */}
                                      <span className="price">
                                        {item.price}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className="action"
                                    style={{ marginLeft: "18%" }}
                                  >
                                    <div className="number">#{item.top}</div>
                                    {/* <div className="btn-follow">
                                    <Link to="/login">Follow</Link>
                                  </div> */}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        ))}
                      </TabPanel>
                    ))}
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TopCollections;

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import CardModal from "../components/layouts/CardModal";

import avt from "../assets/images/avatar/avt-author-tab.jpg";

import {
  NFTContract,
  getMerketplaceContract,
  getPublicKey,
  getWallet,
} from "./../utils/GetContract";
import { LoadingDots } from "../components/LoadingDots";
import { NFTAbi__factory } from "../contracts/nft";

const Author = () => {
  const [publicKey, setPublicKey] = useState();
  const [userData, setUserData] = useState();
  const [nfts, setNfts] = useState();
  const [listedNfts, setListedNfts] = useState();
  const [offerNfts, setOfferNfts] = useState();
  const [offeredNfts, setOfferedNfts] = useState();
  const [contractCall, setContractCall] = useState(false);

  const ud = localStorage.getItem("fuelart-ud");

  const loadUserData = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_AMRKETPLACE_API_URL}/user/${publicKey}`
    );
    let result = await data.json();

    let social_list = [];
    if (result.twitter)
      social_list.push({
        icon: "fab fa-twitter",
        link: result.twitter,
      });
    if (result.discord)
      social_list.push({
        icon: "icon-fl-vt",
        link: result.discord,
      });
    if (result.facebook)
      social_list.push({
        icon: "fab fa-facebook",
        link: result.facebook,
      });
    setUserData({
      name: result.name,
      bio: result.bio,
      profile_image: result.profile_image,
      social_list: social_list,
    });
  };
  const loadData = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_WALLET_NFTS_URL}/owner-nfts/${publicKey.slice(
        2
      )}`
    );
    let result = await data.json();
    const panelTab = [
      {
        id: 1,
        dataContent: result,
      },
    ];
    setNfts(panelTab);
    // } else {
    //   alert("Connect Fuel's Wallet!");
    // }
  };

  useEffect(() => {
    const public_key = async () => {
      const publicKey = await getPublicKey();
      setPublicKey(publicKey);
    };
    public_key();
    loadData();
    loadUserData();
  }, [publicKey]);

  const [visible, setVisible] = useState(8);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 4);
  };

  const [modalShow, setModalShow] = useState(false);

  const listed_nfts = async () => {
    if (!listedNfts) {
      const data = await fetch(
        `${
          process.env.REACT_APP_AMRKETPLACE_API_URL
        }/user-listed-nfts/${publicKey.slice(2)}`
      );
      let result = await data.json();
      const panelTab = [
        {
          id: 1,
          dataContent: result,
        },
      ];
      setListedNfts(panelTab);
    }
  };

  const offer_nfts = async () => {
    if (!offerNfts) {
      const data = await fetch(
        `${
          process.env.REACT_APP_AMRKETPLACE_API_URL
        }/user-offer-nfts/${publicKey.slice(2)}`
      );
      let result = await data.json();
      const panelTab = [
        {
          id: 1,
          dataContent: result,
        },
      ];
      setOfferNfts(panelTab);
    }
  };

  const offered_nfts = async () => {
    if (!offeredNfts) {
      const data = await fetch(
        `${
          process.env.REACT_APP_AMRKETPLACE_API_URL
        }/offered-nfts/${publicKey.slice(2)}`
      );
      let result = await data.json();
      console.log(result);
      const panelTab = [
        {
          id: 1,
          dataContent: result,
        },
      ];
      setOfferedNfts(panelTab);
    }
  };

  const accept_offer = async (listed, id, token) => {
    const contract_id = "0x" + id;
    setContractCall(true);
    try {
      if (listed) {
        const contract = await getMerketplaceContract();
        const accept_offer = await contract.functions
          .accept_offer({ value: contract_id }, parseInt(token))
          .addContracts([NFTContract(contract_id)])
          .txParams({ gasPrice: 1 })
          .call();
        console.log("change_nft_price: ", accept_offer);
        alert("Congratulations on your fuel nft selling!");
        window.location.reload();
      } else {
        const wallet = await getWallet();
        const contractInstance = await getMerketplaceContract();
        const nftcontractInstance = NFTAbi__factory.connect(
          contract_id,
          wallet,
          {
            cache: false,
          }
        );
        const calls = [
          nftcontractInstance.functions.approve(
            { ContractId: { value: contractInstance.id } },
            token
          ),
          contractInstance.functions.accept_offer(
            { value: contract_id },
            parseInt(token)
          ),
        ];
        const scope = contractInstance
          .multiCall(calls)
          .addContracts([NFTContract(contract_id)]);

        const results = await scope.txParams({ gasPrice: 10 }).call();
        console.log("calls: ", results);
        window.location.reload();
      }
    } catch {
      console.log("some error");
    } finally {
      setContractCall(false);
    }
  };

  return (
    <div className="authors-2">
      {contractCall ? <LoadingDots fullScreen={true} /> : <></>}
      <section className="tf-section authors">
        <div className="themesflat-container mt-5">
          <div className="flat-tabs tab-authors">
            <div className="author-profile flex">
              <div className="feature-profile">
                {userData && userData.profile_image ? (
                  <img
                    src={userData.profile_image}
                    alt="Fuelart"
                    className="avatar"
                  />
                ) : (
                  <img src={avt} alt="Fuelart" className="avatar" />
                )}
              </div>
              <div className="infor-profile">
                <span>Author Profile</span>
                <h2 className="title">
                  {ud ? ud : <>{userData ? userData.name : <>Unnamed</>}</>}
                </h2>
                <p className="content">
                  {userData && userData.bio ? userData.bio : <>I am ghost...</>}
                </p>
                <form>
                  <input
                    type="text"
                    className="inputcopy"
                    defaultValue={publicKey}
                    readOnly
                  />
                  <button type="button" className="btn-copycode">
                    <i className="icon-fl-file-1"></i>
                  </button>
                </form>
              </div>
              <div className="widget-social style-3">
                <ul>
                  {userData ? (
                    userData.social_list.map((item, index) => (
                      <li key={index}>
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className={item.icon}></i>
                        </a>
                      </li>
                    ))
                  ) : (
                    <></>
                  )}
                </ul>
                {/* <div className="btn-profile">
                  <Link to="/login" className="sc-button style-1 follow">
                    Follow
                  </Link>
                </div>  */}
              </div>
            </div>
            <Tabs>
              <TabList>
                <Tab>MY NFTS</Tab>
                <Tab onClick={listed_nfts}>LISTED</Tab>
                <Tab onClick={offer_nfts}>OFFER</Tab>
                <Tab onClick={offered_nfts}>OFFERED</Tab>
              </TabList>
              <TabPanel>
                {nfts ? (
                  nfts.length ? (
                    nfts.map((item, index) => (
                      <div className="row mt-3" key={index}>
                        {item.dataContent.map((data, index) => (
                          <div
                            key={index}
                            className="col-xl-3 col-lg-4 col-md-6 col-12"
                          >
                            <div className="sc-card-product explode style2 mg-bt nft-card">
                              <div className="card-media">
                                <Link
                                  to={`/asset/${"0x" + data.nft_contract}/${
                                    data.token_id
                                  }`}
                                >
                                  <img
                                    src={data.nft_data.image}
                                    alt="Fuelart"
                                  />
                                </Link>
                              </div>
                              <div className="card-title mg-bt-16">
                                <h5>
                                  <Link
                                    to={`/sell/${"0x" + data.nft_contract}/${
                                      data.token_id
                                    }`}
                                  >
                                    "{data.nft_data.name}"
                                  </Link>
                                </h5>
                              </div>
                              <div className="meta-info style-explode card-bottom">
                                <Link
                                  className="sc-button bag fl-button pri-3"
                                  to={`/sell/${"0x" + data.nft_contract}/${
                                    data.token_id
                                  }`}
                                >
                                  <span>List </span>
                                </Link>
                                <div className="tags">FUEL</div>
                              </div>
                            </div>
                          </div>
                        ))}
                        {/* {visible < item.dataContent.length && (
                          <div className="col-md-12 wrap-inner load-more text-center">
                            <Link
                              to="#"
                              id="load-more"
                              className="sc-button loadmore fl-button pri-3"
                              onClick={showMoreItems}
                            >
                              <span>Load More</span>
                            </Link>
                          </div>
                        )} */}
                      </div>
                    ))
                  ) : (
                    <div className="col-md-12 wrap-inner load-more text-center fs-16">
                      <h5>No NFTs found / Wallet is not connected</h5>
                    </div>
                  )
                ) : (
                  <div className="col-md-12 wrap-inner load-more text-center fs-16">
                    <LoadingDots />
                  </div>
                )}
              </TabPanel>
              <TabPanel>
                {listedNfts ? (
                  listedNfts.length ? (
                    listedNfts.map((item, index) => (
                      <div className="row mt-3" key={index}>
                        {item.dataContent.map((data, index) => (
                          <div
                            key={index}
                            className="col-xl-3 col-lg-4 col-md-6 col-12"
                          >
                            <div className="sc-card-product explode style2 mg-bt nft-card">
                              <div className="card-media">
                                <Link
                                  to={`/asset/${"0x" + data.nft_contract}/${
                                    data.token_id
                                  }`}
                                >
                                  <img
                                    src={data.nft_data.image}
                                    alt="Fuelart"
                                  />
                                </Link>
                              </div>
                              <div className="card-title mg-bt-16">
                                <h5>
                                  <Link
                                    to={`/asset/${"0x" + data.nft_contract}/${
                                      data.token_id
                                    }`}
                                  >
                                    "{data.nft_data.name}"
                                  </Link>
                                </h5>
                                <div className="tags">FUEL</div>
                              </div>
                            </div>
                          </div>
                        ))}
                        {/* {visible < item.dataContent.length && (
                          <div className="col-md-12 wrap-inner load-more text-center">
                            <Link
                              to="#"
                              id="load-more"
                              className="sc-button loadmore fl-button pri-3"
                              onClick={showMoreItems}
                            >
                              <span>Load More</span>
                            </Link>
                          </div>
                        )} */}
                      </div>
                    ))
                  ) : (
                    <div className="col-md-12 wrap-inner load-more text-center fs-16">
                      <h5>No NFTs found / Wallet is not connected</h5>
                    </div>
                  )
                ) : (
                  <div className="col-md-12 wrap-inner load-more text-center fs-16">
                    <LoadingDots />
                  </div>
                )}
              </TabPanel>
              <TabPanel>
                {offerNfts ? (
                  offerNfts.length ? (
                    offerNfts.map((item, index) => (
                      <div className="row mt-3" key={index}>
                        {item.dataContent.map((data, index) => (
                          <div
                            key={index}
                            className="col-xl-3 col-lg-4 col-md-6 col-12"
                          >
                            <div className="sc-card-product explode style2 mg-bt nft-card">
                              <div className="card-media">
                                <Link
                                  to={`/asset/${"0x" + data.nft_contract}/${
                                    data.token_id
                                  }`}
                                >
                                  <img
                                    src={data.nft_data.image}
                                    alt="Fuelart"
                                  />
                                </Link>
                              </div>
                              <div className="card-title mg-bt-16">
                                <h5>
                                  <Link
                                    to={`/asset/${"0x" + data.nft_contract}/${
                                      data.token_id
                                    }`}
                                  >
                                    "{data.nft_data.name}"
                                  </Link>
                                </h5>
                                <div className="tags">FUEL</div>
                              </div>
                              <div className=" style-explode card-bottom">
                                <h5 className>
                                  Offer:- {data.offer.price.toFixed(3) / 1e9}{" "}
                                  ETH
                                </h5>
                                <button
                                  className="sc-button loadmore bag fl-button"
                                  onClick={() =>
                                    accept_offer(
                                      data.status,
                                      data.nft_contract,
                                      data.token_id
                                    )
                                  }
                                >
                                  <span>Accept </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="col-md-12 wrap-inner load-more text-center fs-16">
                      <h5>No NFTs found / Wallet is not connected</h5>
                    </div>
                  )
                ) : (
                  <div className="col-md-12 wrap-inner load-more text-center fs-16">
                    <LoadingDots />
                  </div>
                )}
              </TabPanel>
              <TabPanel>
                {offeredNfts ? (
                  offeredNfts.length ? (
                    offeredNfts.map((item, index) => (
                      <div className="row mt-3" key={index}>
                        {item.dataContent.map((data, index) => (
                          <div
                            key={index}
                            className="col-xl-3 col-lg-4 col-md-6 col-12"
                          >
                            <div className="sc-card-product explode style2 mg-bt nft-card">
                              <div className="card-media">
                                <Link
                                  to={`/asset/${"0x" + data.nft_contract}/${
                                    data.token_id
                                  }`}
                                >
                                  <img
                                    src={data.nft_data.image}
                                    alt="Fuelart"
                                  />
                                </Link>
                              </div>
                              <div className="card-title mg-bt-16">
                                <h5>
                                  <Link
                                    to={`/asset/${"0x" + data.nft_contract}/${
                                      data.token_id
                                    }`}
                                  >
                                    "{data.nft_data.name}"
                                  </Link>
                                </h5>
                                <div className="tags">FUEL</div>
                              </div>
                              <div className=" style-explode card-bottom">
                                <h5 className>
                                  Offer:- {data.offer.price.toFixed(3) / 1e9}{" "}
                                  ETH
                                </h5>
                                {/* <button
                                  className="sc-button loadmore bag fl-button"
                                  onClick={() =>
                                    accept_offer(
                                      data.status,
                                      data.nft_contract,
                                      data.token_id
                                    )
                                  }
                                >
                                  <span>Cancel </span>
                                </button> */}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="col-md-12 wrap-inner load-more text-center fs-16">
                      <h5>No NFTs found / Wallet is not connected</h5>
                    </div>
                  )
                ) : (
                  <div className="col-md-12 wrap-inner load-more text-center fs-16">
                    <LoadingDots />
                  </div>
                )}
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </section>
      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default Author;

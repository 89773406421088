import React from "react";
import { Link } from "react-router-dom";
import icon1 from "../../../assets/images/icon/Wallet.png";
import icon2 from "../../../assets/images/icon/Category.png";
import icon3 from "../../../assets/images/icon/Image2.png";
import icon4 from "../../../assets/images/icon/Bookmark.png";

const Create = () => {
  const data = [
    {
      extternal: true,
      title: "Set Up Your Wallet",
      description:
        "Wallet that is functional for NFT purchasing. You may have a Coinbase account at this point, but very few are actually set up to buy an NFT.",
      icon: icon1,
      colorbg: "icon-color1",
      to: "https://chrome.google.com/webstore/detail/fuel-wallet/dldjpboieedgcmpkchcjcbijingjcgok",
    },
    // {
    //   title: "Create Your Collection",
    //   description:
    //     "Setting up your NFT collection and creating NFTs on NFTs is easy! This guide explains how to set up your first collection",
    //   icon: icon2,
    //   colorbg: "icon-color2",
    // },
    {
      title: "Create Your NFTs",
      description:
        "Now you can create your own NFT on fuel network. Go create your own NFT",
      icon: icon3,
      colorbg: "icon-color3",
      to: "/create-item",
    },
    {
      title: "List Them For Sale",
      description:
        "Choose your NFT fixed-price listings, and put a prize. You choose how you want to sell your NFTs!",
      icon: icon4,
      colorbg: "icon-color4",
      to: "/author",
    },
  ];
  return (
    <section className="tf-box-icon create style1 tf-section home6 bg-style3">
      <div className="themesflat-container">
        <div className="row">
          {data.map((item, index) => (
            <CreateItem key={index} item={item} />
          ))}
        </div>
      </div>
    </section>
  );
};

const CreateItem = (props) => (
  <div className="col-lg-4 col-md-6 col-12">
    <div className="sc-box-icon">
      <div className="image">
        <div className={`icon-create ${props.item.colorbg}`}>
          <img src={props.item.icon} alt="" />
        </div>
      </div>
      <h3 className="heading">
        {/* <Link to="/wallet-connect">{props.item.title}</Link> */}
        {props.item.extternal ? (
          <a href={props.item.to} rel="noopener noreferrer" target="_blank">
            {props.item.title}
          </a>
        ) : (
          <a href={props.item.to} rel="noopener noreferrer">
            {props.item.title}
          </a>
        )}
        {/* <a href={props.item.to} rel="noopener noreferrer">
          {props.item.title}
        </a> */}
      </h3>
      <p className="content">{props.item.description}</p>
    </div>
  </div>
);

export default Create;

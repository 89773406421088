import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import TodayPicks from "../components/layouts/explore-02/TodayPicks";
import { useSelector } from "react-redux";
import AllNFTs from "../components/layouts/AllNFTs";

const Explore02 = () => {
  return (
    <div className="explore">
      {/* <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container mt-5">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Explore</h1>
              </div>
              <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="#">Explore</Link>
                  </li>
                  <li>Explore 2</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <AllNFTs />
      {/* <Footer /> */}
    </div>
  );
};

export default Explore02;

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import CardModal from "../components/layouts/CardModal";

import avt from "../assets/images/avatar/avt-author-tab.jpg";

import { LoadingDots } from "../components/LoadingDots";

const Authors02 = () => {
  const [nfts, setNfts] = useState();
  const [listedNfts, setListedNfts] = useState();
  const [userData, setUserData] = useState();

  const { publicKey } = useParams();

  const ud = localStorage.getItem("fuelart-ud");

  const loadUserData = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_AMRKETPLACE_API_URL}/user/${publicKey}`
    );
    let result = await data.json();
    let social_list = [];
    if (result.twitter)
      social_list.push({
        icon: "fab fa-twitter",
        link: result.twitter,
      });
    if (result.discord)
      social_list.push({
        icon: "icon-fl-vt",
        link: result.discord,
      });
    if (result.facebook)
      social_list.push({
        icon: "fab fa-facebook",
        link: result.facebook,
      });
    setUserData({
      name: result.name,
      bio: result.bio,
      profile_image: result.profile_image,
      social_list: social_list,
    });
  };
  const loadData = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_WALLET_NFTS_URL}/owner-nfts/${publicKey.slice(
        2
      )}`
    );
    let result = await data.json();
    const panelTab = [
      {
        id: 1,
        dataContent: result,
      },
    ];
    setNfts(panelTab);
    // } else {
    //   alert("Connect Fuel's Wallet!");
    // }
  };

  useEffect(() => {
    loadData();
    loadUserData();
  }, [publicKey]);

  const [visible, setVisible] = useState(8);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 4);
  };

  const [modalShow, setModalShow] = useState(false);

  const listed_nfts = async () => {
    if (!listedNfts) {
      const data = await fetch(
        `${
          process.env.REACT_APP_AMRKETPLACE_API_URL
        }/user-listed-nfts/${publicKey.slice(2)}`
      );
      let result = await data.json();
      const panelTab = [
        {
          id: 1,
          dataContent: result,
        },
      ];
      setListedNfts(panelTab);
    }
  };

  return (
    <div className="authors-2">
      <section className="tf-section authors">
        <div className="themesflat-container mt-5">
          <div className="flat-tabs tab-authors">
            <div className="author-profile flex">
              <div className="feature-profile">
                {userData && userData.profile_image ? (
                  <img
                    src={userData.profile_image}
                    alt="Fuelart"
                    className="avatar"
                  />
                ) : (
                  <img src={avt} alt="Fuelart" className="avatar" />
                )}
              </div>
              <div className="infor-profile">
                <span>Author Profile</span>
                <h2 className="title">
                  {ud ? ud : <>{userData ? userData.name : <>Unnamed</>}</>}
                </h2>
                <p className="content">
                  {userData && userData.bio ? (
                    userData.bio
                  ) : (
                    <>I am a ghost...</>
                  )}
                </p>
                <form>
                  <input
                    type="text"
                    className="inputcopy"
                    defaultValue={publicKey}
                    readOnly
                  />
                  <button type="button" className="btn-copycode">
                    <i className="icon-fl-file-1"></i>
                  </button>
                </form>
              </div>
              <div className="widget-social style-3">
                <ul>
                  {userData ? (
                    userData.social_list.map((item, index) => (
                      <li key={index}>
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className={item.icon}></i>
                        </a>
                      </li>
                    ))
                  ) : (
                    <></>
                  )}
                </ul>
                {/* <div className="btn-profile">
                  <Link to="/login" className="sc-button style-1 follow">
                    Follow
                  </Link>
                </div>  */}
              </div>
            </div>
            <Tabs>
              <TabList>
                <Tab>MY NFTS</Tab>
                <Tab onClick={listed_nfts}>LISTED</Tab>
              </TabList>
              <TabPanel>
                {nfts ? (
                  nfts.length ? (
                    nfts.map((item, index) => (
                      <div className="row mt-3" key={index}>
                        {item.dataContent
                          .slice(0, visible)
                          .map((data, index) => (
                            <div
                              key={index}
                              className="col-xl-3 col-lg-4 col-md-6 col-12"
                            >
                              <div className="sc-card-product explode style2 mg-bt nft-card">
                                <div className="card-media">
                                  <Link
                                    to={`/asset/${"0x" + data.nft_contract}/${
                                      data.token_id
                                    }`}
                                  >
                                    <img
                                      src={data.nft_data.image}
                                      alt="Fuelart"
                                    />
                                  </Link>
                                </div>
                                <div className="card-title mg-bt-16">
                                  <h5>
                                    <Link
                                      to={`/asset/${"0x" + data.nft_contract}/${
                                        data.token_id
                                      }`}
                                    >
                                      "{data.nft_data.name}"
                                    </Link>
                                  </h5>
                                  <div className="tags">FUEL</div>
                                </div>
                              </div>
                            </div>
                          ))}
                        {visible < item.dataContent.length && (
                          <div className="col-md-12 wrap-inner load-more text-center">
                            <Link
                              to="#"
                              id="load-more"
                              className="sc-button loadmore fl-button pri-3"
                              onClick={showMoreItems}
                            >
                              <span>Load More</span>
                            </Link>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="col-md-12 wrap-inner load-more text-center fs-16">
                      <h5>No NFTs found / Wallet is not connected</h5>
                    </div>
                  )
                ) : (
                  <div className="col-md-12 wrap-inner load-more text-center fs-16">
                    <LoadingDots />
                  </div>
                )}
              </TabPanel>
              <TabPanel>
                {listedNfts ? (
                  listedNfts.length ? (
                    listedNfts.map((item, index) => (
                      <div className="row mt-3" key={index}>
                        {item.dataContent
                          .slice(0, visible)
                          .map((data, index) => (
                            <div
                              key={index}
                              className="col-xl-3 col-lg-4 col-md-6 col-12"
                            >
                              <div className="sc-card-product explode style2 mg-bt nft-card">
                                <div className="card-media">
                                  <Link
                                    to={`/asset/${"0x" + data.nft_contract}/${
                                      data.token_id
                                    }`}
                                  >
                                    <img
                                      src={data.nft_data.image}
                                      alt="Fuelart"
                                    />
                                  </Link>
                                </div>
                                <div className="card-title mg-bt-16">
                                  <h5>
                                    <Link
                                      to={`/asset/${"0x" + data.nft_contract}/${
                                        data.token_id
                                      }`}
                                    >
                                      "{data.nft_data.name}"
                                    </Link>
                                  </h5>
                                  <div className="tags">FUEL</div>
                                </div>
                              </div>
                            </div>
                          ))}
                        {visible < item.dataContent.length && (
                          <div className="col-md-12 wrap-inner load-more text-center">
                            <Link
                              to="#"
                              id="load-more"
                              className="sc-button loadmore fl-button pri-3"
                              onClick={showMoreItems}
                            >
                              <span>Load More</span>
                            </Link>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="col-md-12 wrap-inner load-more text-center fs-16">
                      <h5>No NFTs found / Wallet is not connected</h5>
                    </div>
                  )
                ) : (
                  <div className="col-md-12 wrap-inner load-more text-center fs-16">
                    <LoadingDots />
                  </div>
                )}
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </section>
      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default Authors02;

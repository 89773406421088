import { Navigation, A11y, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/effect-coverflow";
import angerImg from "../../assets/images/test/anger.webp";
import { Link } from "react-router-dom";

const slides = [
  {
    id: 1,
    imgUrl: angerImg,
    title: "this is title",
    subTitle: "this is sub",
    avatar: angerImg,
    contract:
      "0x9ca97176d24ae2f0461b9176ef5385b56f3f2c693d590919a8501046f1e4aea8",
  },
  {
    id: 2,
    imgUrl: angerImg,
    title: "this is title",
    subTitle: "this is sub",
    avatar: angerImg,
    contract:
      "0x9ca97176d24ae2f0461b9176ef5385b56f3f2c693d590919a8501046f1e4aea8",
  },
  {
    id: 3,
    imgUrl: angerImg,
    title: "this is title",
    subTitle: "this is sub",
    avatar: angerImg,
    contract:
      "0x9ca97176d24ae2f0461b9176ef5385b56f3f2c693d590919a8501046f1e4aea8",
  },
  {
    id: 4,
    imgUrl: angerImg,
    title: "this is title",
    subTitle: "this is sub",
    avatar: angerImg,
    contract:
      "0x9ca97176d24ae2f0461b9176ef5385b56f3f2c693d590919a8501046f1e4aea8",
  },
  {
    id: 5,
    imgUrl: angerImg,
    title: "this is title",
    subTitle: "this is sub",
    avatar: angerImg,
    contract:
      "0x9ca97176d24ae2f0461b9176ef5385b56f3f2c693d590919a8501046f1e4aea8",
  },
  {
    id: 6,
    imgUrl: angerImg,
    title: "this is title",
    subTitle: "this is sub",
    avatar: angerImg,
    contract:
      "0x9ca97176d24ae2f0461b9176ef5385b56f3f2c693d590919a8501046f1e4aea8",
  },
  {
    id: 7,
    imgUrl: angerImg,
    title: "this is title",
    subTitle: "this is sub",
    avatar: angerImg,
    contract:
      "0x9ca97176d24ae2f0461b9176ef5385b56f3f2c693d590919a8501046f1e4aea8",
  },
  // { id: 8, imgUrl: angerImg },
  // { id: 9, imgUrl: angerImg },
  // { id: 10, imgUrl: angerImg },
];

export const Slider3 = () => {
  return (
    <div className="slider3">
      <Swiper
        modules={[Navigation, A11y, EffectCoverflow]}
        spaceBetween={0}
        effect={"coverflow"}
        centeredSlides={true}
        navigation={true}
        loop={true}
        scrollbar={{ draggable: true }}
        coverflowEffect={{
          rotate: 25,
          stretch: 0,
          depth: 100,
          modifier: 1,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 3,
          },
          1000: {
            slidesPerView: 5,
          },
          // when window width is >= 1200px
          2400: {
            slidesPerView: 7,
          },
        }}
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id}>
            <Link to={`/collection/${slide.contract}`}>
              <div className="nft-card">
                <div className="image-holder">
                  <img src={slide.imgUrl} alt="Nft" />
                </div>
                <div className="details">
                  <div className="avatar">
                    <img src={slide.avatar} alt="avatar" />
                  </div>
                  <div>
                    <h3>{slide.title}</h3>
                    <p>{slide.subTitle}</p>
                  </div>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

import { Address, WalletUnlocked, WalletLocked } from "fuels";
import { NftMarketplaceAbi__factory } from "../contracts/marketplace";
import { NFTAbi__factory } from "../contracts/nft";
import { LaunchpadAbi__factory } from "../contracts/launchpad";

// let pk = "0xde97d8624a438121b86a1956544bd72ed68cd69f2c99555b08b1e8c51ffd511c";

// let pk = localStorage.getItem("private_key") || "";

// if (!pk) {
const pk = "0x6b63804cfbf9856e68e5b6e7aef238dc8311ec55bec04df774003a2c96e0418e";
// }
const w: any = window;

const node = process.env.REACT_APP_FUEL_NETWORK;
export const wallet = new WalletLocked(pk, node);

export const MarketplaceContract = () => {
  let contractId: string;
  let marketplaceContract: any;

  if (process.env.REACT_APP_MARKETPLACE_CONTRACT_ID) {
    contractId = process.env.REACT_APP_MARKETPLACE_CONTRACT_ID;

    marketplaceContract = NftMarketplaceAbi__factory.connect(
      contractId,
      wallet
    );
  }
  return marketplaceContract;
};

export const NFTContract = (NFTContractId: any) => {
  return NFTAbi__factory.connect(NFTContractId, wallet);
};

export const LaunchpadContract = (contractId: any) => {
  return LaunchpadAbi__factory.connect(contractId, wallet);
};

export const getWallet = async () => {
  if (w.fuel) {
    const accounts = await w.fuel.accounts();
    const publicKey = accounts[0];

    return w.fuel.getWallet(publicKey);
  }
  // if (localStorage.getItem("publicKey")) {
  //   console.log(localStorage.getItem("publicKey"));
  //   return w.fuel.getWallet(
  //     "fuel1wak20nf87jcjh4glj72j7v0t4s279ej7qylafw804wqammryx4vq0csgr2"
  //   );
  // } else if (w.fuel) {
  //   const accounts = await w.fuel.accounts();
  //   const publicKey = accounts[0];
  //   return w.fuel.getWallet(publicKey);
  // }
};

export const getPublicKey = async () => {
  if (w.fuel) {
    const accounts = await w.fuel.accounts();
    const publicKey = accounts[0];
    return Address.fromAddressOrString(publicKey).toB256();
  }
};

export const getMerketplaceContract = async () => {
  if (process.env.REACT_APP_MARKETPLACE_CONTRACT_ID) {
    const wallet = await getWallet();
    const contractId = process.env.REACT_APP_MARKETPLACE_CONTRACT_ID;
    return NftMarketplaceAbi__factory.connect(contractId, wallet);
  }
};

export const getNftContract = async (contractId: any) => {
  const wallet = await getWallet();
  return NFTAbi__factory.connect(contractId, wallet);
};
